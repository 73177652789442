import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';
import PromoCodeDetails from './views/MarketingReferral/PromoCodeDetails';

function Loading() {
  return <div> Loading... </div>;
}

const VerifyFantasyDomain = Loadable({
  loader: () => import('./views/Common/VerifyFantasyDomain'),
  loading: Loading,
});
const CampaignDashboard = Loadable({
  loader: () => import('./views/Marketing/CommunicationDashboard/CampaignDashboard'),
  loading: Loading,
});
const UserSegmentation = Loadable({
  loader: () => import('./views/Marketing/UserSegmentation/NewCampaign'),
  loading: Loading,
});
const ContestTemplate = Loadable({
  loader: () => import('./views/ContestTemplate/Contesttemplate'),
  loading: Loading,
});
const CreateContestTemplate = Loadable({
  loader: () => import('./views/ContestTemplate/Createtemplate'),
  loading: Loading,
});
const CreateTemplateContest = Loadable({
  loader: () => import('./views/Contest/Createtemplatecontest'),
  loading: Loading,
});

const FixtureContest = Loadable({
  loader: () => import('./views/Contest/Fixturecontest'),
  loading: Loading,
});

const ContestList = Loadable({
  loader: () => import('./views/Contest/Contestlist'),
  loading: Loading,
});
const SLContestList = Loadable({
  loader: () => import('./views/Contest/SLContestlist'),
  loading: Loading,
});
const SystemUserReport = Loadable({
  loader: () => import('./views/SystemUserReport/UsersReport'),
  loading: Loading,
});
const MultigameContestList = Loadable({
  loader: () => import('./views/Contest/MultigameContestlist'),
  loading: Loading,
});

const CreateContest = Loadable({
  loader: () => import('./views/Contest/Createcontest'),
  loading: Loading,
});

const CreateF2PContest = Loadable({
  loader: () => import('./views/Contest/CreateF2Pcontest'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard/Dashboard'),
  loading: Loading,
});

const DFS = Loadable({
  loader: () => import('./views/GameCenter/DFS/DFS'),
  loading: Loading,
});

const Multigame = Loadable({
  loader: () => import('./views/GameCenter/Multigame/Multigame'),
  loading: Loading,
});

const Playing11 = Loadable({
  loader: () => import('./views/GameCenter/DFS/Playing11'),
  loading: Loading,
});
const CreateCollection = Loadable({
  loader: () => import('./views/GameCenter/Multigame/CreateCollection'),
  loading: Loading,
});

const Teams = Loadable({
  loader: () => import('./views/GameCenter/DFS/Teams'),
  loading: Loading,
});

const deals = Loadable({
  loader: () => import('./views/deals/DealList'),
  loading: Loading
})

const AddUser = Loadable({
  loader: () => import('./views/UserManagement/AddUser/AddUser'),
  loading: Loading,
});

const Manageuser = Loadable({
  loader: () => import('./views/UserManagement/Manageuser/Manageuser'),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import('./views/Pages/Login/Logout'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./views/UserManagement/Profile/Profile'),
  loading: Loading,
});

const ManageScoring = Loadable({
  loader: () => import('./views/ManageScoring/ManageScoring'),
  loading: Loading
})

const LobbyBanner = Loadable({
  loader: () => import('./views/Cms/LobbyBanner'),
  loading: Loading
})
const AppBanner = Loadable({
  loader: () => import('./views/Cms/AppBanner'),
  loading: Loading
})


const BackgroundImage = Loadable({
  loader: () => import('./views/Cms/BackgroundImage'),
  loading: Loading
})
const CMS = Loadable({
  loader: () => import('./views/Cms/CMS'),
  loading: Loading
})



//Start marketing module
const REFERRALAMOUNT = Loadable({
  loader: () => import('./views/MarketingReferral/ReferralAmount'),
  loading: Loading
})

const PROMOCODE = Loadable({
  loader: () => import('./views/MarketingReferral/PromoCode'),
  loading: Loading
})

const PROMOCODEDETAILS = Loadable({
  loader: () => import('./views/MarketingReferral/PromoCodeDetails'),
  loading: Loading
})

const WITHDRAWAL_LIST = Loadable({
  loader: () => import('./views/Finance/WithdrawalList'),
  loading: Loading
})

const USER_REPORTS = Loadable({
  loader: () => import('./views/Report/UserReport'),
  loading: Loading
})

const USER_MONEY_PAID = Loadable({
  loader: () => import('./views/Report/UserMoneyPaid'),
  loading: Loading
})

const USER_DEPOSIT_MONEY = Loadable({
  loader: () => import('./views/Report/UserDepositMoney'),
  loading: Loading
})

const REFERRAL_REPORT = Loadable({
  loader: () => import('./views/Report/UserReferralReport'),
  loading: Loading
})

const CONTEST_REPORT = Loadable({
  loader: () => import('./views/Report/UserContestReport'),
  loading: Loading
})


const TRANSACTION_LIST = Loadable({
  loader: () => import('./views/Finance/TransactionList'),
  loading: Loading
})

const CONTEST_DETAILS = Loadable({
  loader: () => import('./views/Finance/ContestDetails'),
  loading: Loading
})

const LANGUAGE_UPLOAD = Loadable({
  loader: () => import('./views/Common/LanguageUpload'),
  loading: Loading
})

const CoinsDashboard = Loadable({
  loader: () => import('./views/Coins/CoinsDashboard'),
  loading: Loading
})
const CoinsSetting = Loadable({
  loader: () => import('./views/Coins/CoinsSetting'),
  loading: Loading
})
const Redeem = Loadable({
  loader: () => import('./views/Coins/Redeem'),
  loading: Loading
})
const Merchandise = Loadable({
  loader: () => import('./views/Merchandise/Merchandise'),
  loading: Loading
})
const Promotions = Loadable({
  loader: () => import('./views/Coins/Promotions'),
  loading: Loading
})

const QuestionDetails = Loadable({
  loader: () => import('./views/Coins/QuestionDetails'),
  loading: Loading
})

const TopEarner = Loadable({
  loader: () => import('./views/Coins/TopEarner'),
  loading: Loading
})

const RedeemCoin = Loadable({
  loader: () => import('./views/Coins/RedeemCoin'),
  loading: Loading
})

const SpinTheWheel = Loadable({
  loader: () => import('./views/Coins/SpinTheWheel'),
  loading: Loading
})


const PredictionType = Loadable({
  loader: () => import('./views/PredictionType/PredictionType'),
  loading: Loading
})

const PredictionDashboard = Loadable({
  loader: () => import('./views/PredictionType/PredictionDashboard'),
  loading: Loading
})

const MostWinBid = Loadable({
  loader: () => import('./views/PredictionType/MostWinBid'),
  loading: Loading
})

const PredictionFixture = Loadable({
  loader: () => import('./views/PredictionType/PredictionFixture'),
  loading: Loading
})

const SetPrediction = Loadable({
  loader: () => import('./views/PredictionType/SetPrediction'),
  loading: Loading
})

const SEASON_SCHEDULE = Loadable({
  loader: () => import('./views/GameCenter/DFS/Seasonschedule'),
  loading: Loading,
});

const CreateNewPick = Loadable({
  loader: () => import('./views/Pickem/CreateNewPick'),
  loading: Loading
})

const JoinedUserList = Loadable({
  loader: () => import('./views/Pickem/JoinedUserList'),
  loading: Loading
  })
  
const PredictionCompletedQues = Loadable({
  loader: () => import('./views/PredictionType/PredictionCompletedQues'),
  loading: Loading
})

const SystemUsersList = Loadable({
  loader: () => import('./views/SystemUsers/SystemUsersList'),
  loading: Loading
})

const AddSystemUser = Loadable({
  loader: () => import('./views/SystemUsers/AddSystemUser'),
  loading: Loading
})

const AddNetwGameSysUser = Loadable({
  loader: () => import('./views/SystemUsers/AddNetwGameSysUser'),
  loading: Loading
})


//Start open Predictor
const OpenPredictionType = Loadable({
  loader: () => import('./views/OpenPredictor/PredictionType'),
  loading: Loading
})

const OpenPredictionDashboard = Loadable({
  loader: () => import('./views/OpenPredictor/PredictionDashboard'),
  loading: Loading
})

const OpenMostWinBid = Loadable({
  loader: () => import('./views/OpenPredictor/MostWinBid'),
  loading: Loading
})



const OpenSetPrediction = Loadable({
  loader: () => import('./views/OpenPredictor/SetPrediction'),
  loading: Loading
})

const OpenPredictionCompletedQues = Loadable({
  loader: () => import('./views/OpenPredictor/PredictionCompletedQues'),
  loading: Loading
})

const PredictionCategory = Loadable({
  loader: () => import('./views/OpenPredictor/PredictionCategory'),
  loading: Loading
})

const PredictionCreateCategory = Loadable({
  loader: () => import('./views/OpenPredictor/PredictionCreateCategory'),
  loading: Loading
})
//End open Predictor


//Start Prize Open Predictor
const PrizeOpenPredictionType = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/PredictionType'),
  loading: Loading
})

const PrizeOpenPredictionDashboard = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/PredictionDashboard'),
  loading: Loading
})

const PrizeOpenMostWinBid = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/MostWinBid'),
  loading: Loading
})

const PrizeOpenSetPrediction = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/SetPrediction'),
  loading: Loading
})

const PrizeOpenPredictionCompletedQues = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/PredictionCompletedQues'),
  loading: Loading
})

const PrizePredictionCategory = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/PredictionCategory'),
  loading: Loading
})

const PrizePredictionCreateCategory = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/PredictionCreateCategory'),
  loading: Loading
})

const SetPrize = Loadable({
  loader: () => import('./views/OpenPredictorWithPrize/SetPrize'),
  loading: Loading
})
//End Prize Open Predictor

const FixtureUpdateSalary = Loadable({
  loader: () => import('./views/GameCenter/DFS/FixtureUpdateSalary'),
  loading: Loading,
});

//Mini league starts here
const CreateMiniLeague = Loadable({
  loader: () => import('./views/Free2Play/CreateMiniLeague'),
  loading: Loading,
});

const EditMiniLeague = Loadable({
  loader: () => import('./views/Free2Play/EditMiniLeague'),
  loading: Loading,
});
const EditMiniLeagueFixture = Loadable({
  loader: () => import('./views/Free2Play/EditMiniLeagueFixture'),
  loading: Loading,
});

const MiniLeagueDetail = Loadable({
  loader: () => import('./views/Free2Play/MiniLeagueDetail'),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import('./views/ChangePassword/ChangePassword'),
  loading: Loading,
});

//Start pickem module
const ViewPicks = Loadable({
  loader: () => import('./views/Pickem/ViewPicks'),
  loading: Loading
})

const LeaguesPlayers = Loadable({
  loader: () => import('./views/Pickem/LeaguesPlayers'),
  loading: Loading
})

const PickemDasboard = Loadable({
  loader: () => import('./views/Pickem/PickemDasboard'),
  loading: Loading
})

const PickemMostWinBid = Loadable({
  loader: () => import('./views/Pickem/MostWinBid'),
  loading: Loading
})
//End pickem module

const ForgotPassword = Loadable({
  loader:()=>import('./views/Pages/ForgotPassword/ForgotPassword'),
  loading: Loading
});
//Start admin role management
const AddRole = Loadable({
  loader: () => import('./views/AdminRole/AddRole'),
  loading: Loading
})

const AdminRoleList = Loadable({
  loader: () => import('./views/AdminRole/AdminRoleList'),
  loading: Loading
})

const WelcomeAdmin = Loadable({
  loader: () => import('./views/AdminRole/WelcomeAdmin'),
  loading: Loading
})

//End admin role management

	const DISTRIBUTOR = Loadable({
	  loader: () => import('./views/Distributor/Distributor'),
	  loading: Loading
  });
  
	const ADD_DISTRIBUTOR = Loadable({
	  loader: () => import('./views/Distributor/Add'),
	  loading: Loading
	});



//Start new communication dashboard module
const CreateNewCampaign = Loadable({
  loader: () => import('./views/Marketing/CommunicationCampaign/CreateNewCampaign'),
  loading: Loading,
});

const CustomTemplate = Loadable({
  loader: () => import('./views/Marketing/CommunicationCampaign/CustomTemplate'),
  loading: Loading,
});

const CreateUserbaseList = Loadable({
  loader: () => import('./views/Marketing/CommunicationCampaign/CreateUserbaseList'),
  loading: Loading,
});
//End new communication dashboard module

//Start affiliate module
const Affiliate = Loadable({
  loader: () => import('./views/Affiliate/Affiliate'),
  loading: Loading
})

const AddAffiliate = Loadable({
  loader: () => import('./views/Affiliate/AddAffiliate'),
  loading: Loading
})

const AffiliateDashboard = Loadable({
  loader: () => import('./views/Affiliate/AffiliateDashboard'),
  loading: Loading
})

const AffiliateUsers = Loadable({
  loader: () => import('./views/Affiliate/AffiliateUsers'),
  loading: Loading
})

const HubPage = Loadable({
  loader: () => import('./views/Cms/HubPage'),
  loading: Loading
})

const Lobby = Loadable({
  loader: () => import('./views/Cms/Lobby'),
  loading: Loading
})

const WalletSetting = Loadable({
  loader: () => import('./views/Cms/WalletSetting'),
  loading: Loading
})

const MinimumWithdrawl = Loadable({
  loader: () => import('./views/Cms/MinimumWithdrawl'),
  loading: Loading
})
//End affiliate module

const EmailSetting = Loadable({
  loader: () => import('./views/Settings/EmailSetting'),
  loading: Loading
})

const BuyCoin = Loadable({
  loader: () => import('./views/Coins/BuyCoin'),
  loading: Loading
})

const BuyCoinUserReport = Loadable({
  loader: () => import('./views/Coins/BuyCoinUserReport'),
  loading: Loading
})

const AppSettingForm = Loadable({
  loader: () => import('./views/AppSettingForm/AppSettingForm'),
  loading: Loading
})

const ContestCategory = Loadable({
  loader: () => import('./views/GameCenter/DFS/ContestCategory'),
  loading: Loading,
});
const ReferralSetprize = Loadable({
  loader: () => import('./views/MarketingReferral/ReferralSetprize'),
  loading: Loading
})

const ReferralLeaderboard = Loadable({
  loader: () => import('./views/MarketingReferral/ReferralLeaderboard'),
  loading: Loading
})
//Start network game
const NetworkGame = Loadable({
  loader: () => import('./views/NetworkGame/NetworkGame'),
  loading: Loading,
});

const NetworkGameDetails = Loadable({
  loader: () => import('./views/NetworkGame/NetworkGameDetails'),
  loading: Loading,
});

const NetworkCommission = Loadable({
  loader: () => import('./views/NetworkGame/NetworkCommission'),
  loading: Loading,
});

const NetworkContestReport = Loadable({
  loader: () => import('./views/NetworkGame/NetworkContestReport'),
  loading: Loading,
});

/* Start Leaderboard */
const Referral_Leaderboard = Loadable({
  loader: () => import('./views/Leaderboard/Referral_Leaderboard'),
  loading: Loading,
});

const Depositor_Leaderboard = Loadable({
  loader: () => import('./views/Leaderboard/Depositor_Leaderboard'),
  loading: Loading,
});

const Winning_Leaderboard = Loadable({
  loader: () => import('./views/Leaderboard/Winning_Leaderboard'),
  loading: Loading,
});

const Feedback_Leaderboard = Loadable({
  loader: () => import('./views/Leaderboard/Feedback_Leaderboard'),
  loading: Loading,
});

const TimeSpent_Leaderboard = Loadable({
  loader: () => import('./views/Leaderboard/TimeSpent_Leaderboard'),
  loading: Loading,
});

const TopTeam_Leaderboard = Loadable({
  loader: () => import('./views/Leaderboard/TopTeam_Leaderboard'),
  loading: Loading,
});

const Withdrawal_Leaderboard = Loadable({
  loader: () => import('./views/Leaderboard/Withdrawal_Leaderboard'),
  loading: Loading,
});

const UserMatchReport = Loadable({
  loader: () => import('./views/Report/UserMatchReport'),
  loading: Loading,
});
/* End Leaderboard */
//End network game

//Start GST module
const GSTDashboard = Loadable({
  loader: () => import('./views/GSTAccounting/GSTDashboard'),
  loading: Loading
})

const GSTReports = Loadable({
  loader: () => import('./views/GSTAccounting/GSTReports'),
  loading: Loading
})

const Reward = Loadable({
  loader: () => import('./views/Settings/Reward'),
  loading: Loading
})
//End GST module

const LeagueManagement = Loadable({
  loader: () => import('./views/LeagueManagement/LeagueManagement'),
  loading: Loading
})

const LeagueSettings = Loadable({
  loader: () => import('./views/LeagueManagement/LeagueSettings'),
  loading: Loading
})

const RemainingFixture = Loadable({
  loader: () => import('./views/LeagueManagement/RemainingFixture'),
  loading: Loading
})

const SelectFixtures = Loadable({
  loader: () => import('./views/LeagueManagement/SelectFixtures'),
  loading: Loading
})

const WeekManagements = Loadable({
  loader: () => import('./views/LeagueManagement/WeekManagements'),
  loading: Loading
})

const MiniLeague = Loadable({
  loader: () => import('./views/MiniLeague/MiniLeague'),
  loading: Loading
})

const Prizes = Loadable({
  loader: () => import('./views/Prizes/Prizes'),
  loading: Loading
})

//FantasXchange

const DraftStarterPack = Loadable({
  loader: () => import('./views/DraftStarterPack/DraftStarterPack'),
  loading: Loading
})

const CompetitionsList = Loadable({
  loader: () => import('./views/Competitions/CompetitionsList'),
  loading: Loading
})

const CreatePrivateCompetition = Loadable({
  loader: () => import('./views/CreatePrivateCompetition/CreatePrivateCompetition'),
  loading: Loading
})

const CreatePublicCompetition = Loadable({
  loader: () => import('./views/CreatePublicCompetition/CreatePublicCompetition'),
  loading: Loading
})

const ManageCompetitions = Loadable({
  loader: () => import('./views/ManageCompetitions/ManageCompetitions'),
  loading: Loading
})

const CompetitionsDetail = Loadable({
  loader: () => import('./views/CompetitionsDetail/CompetitionsDetail'),
  loading: Loading
})


const Shop = Loadable({
  loader: () => import('./views/Shop/Shop'),
  loading: Loading
})
const PromoCode = Loadable({
  loader: () => import('./views/PromoCode/PromoCode'),
  loading: Loading
})
const PromoCodeDetail = Loadable({
  loader: () => import('./views/PromoCode/PromoCodeDetail'),
  loading: Loading
})
const PlayerPack = Loadable({
  loader: () => import('./views/PlayerPack/PlayerPack'),
  loading: Loading
})
const RefferralCode = Loadable({
  loader: () => import('./views/RefferralCode/RefferralCode'),
  loading: Loading
})
const RefferralReport = Loadable({
  loader: () => import('./views/RefferralCode/RefferralReport'),
  loading: Loading
})

const CoinBundleList = Loadable({
  loader: () => import('./views/CoinBundleList/CoinBundleList'),
  loading: Loading
})




const routes = [{
    path: '/',
    name: 'Manageuser',
    component: Manageuser,
    exact: true
  },
  {
    path: '/verify-fantasy-domain/:encoded_auth_key',
    name: 'Verify Domain',
    component: VerifyFantasyDomain
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
   { path: '/game_center/DFS', name: 'DFS', component: DFS},
   { path: '/multigame/Fixtures', name: 'Multigame', component: Multigame},
   { path: '/game_center/Playing11/:league_id/:season_game_uid', name: 'Select Playing11', component: Playing11, exact: true},
   { path: '/game_center/CreateCollection', name: 'Create Collection', component: CreateCollection, exact: true},
   { path: '/Teams', name: 'Teams', component: Teams},
   { path: '/usermanagement/manageuser', name: 'Manage User', component: Manageuser},
  
   { path: '/marketing/user_segmentation', name: 'UserSegmentation', component: UserSegmentation},
   { path: '/marketing/communication_dashboard', name: 'CommunicationDashboard', component: CampaignDashboard },
  
   { path: '/contesttemplate', name: 'ContestTemplate', component: ContestTemplate},
   { path: '/createcontesttemplate', name: 'CreateContestTemplate', component: CreateContestTemplate},

   //contest section
   {
     path: '/contest/createtemplatecontest/:league_id/:season_game_uid/:tab',
     name: 'CreateTemplateContest',
     component: CreateTemplateContest,
     exact: true
   },
   { path: '/contest/createtemplatecollectioncontest/:league_id/:collection_master_id', name: 'CreateTemplateContest', component: CreateTemplateContest, exact: true},
   { path: '/contest/fixturecontest/:league_id/:season_game_uid', name: 'FixtureContest', component: FixtureContest, exact: true},
   { path: '/contest/collectioncontest/:league_id/:collection_master_id', name: 'FixtureContest', component: FixtureContest, exact: true},

   { path: '/contest/createcontest/:league_id/:season_game_uid', name: 'CreateContest', component: CreateContest, exact: true},
   { path: '/contest/createf2pcontest/:league_id/:season_game_uid', name: 'CreateF2PContest', component: CreateF2PContest, exact: true},
   { path: '/contest/createcollectioncontest/:league_id/:collection_master_id', name: 'CreateContest', component: CreateContest, exact: true},
   { path: '/contest/contestlist', name: 'ContestList', component: ContestList, exact: true},
   { path: '/system_user_report', name: 'SystemUserSupport', component: SystemUserReport, exact: true},

   { path: '/seasonlong_contests', name: 'SLContestList', component: SLContestList, exact: true},

   { path: '/contest/multigamecontest', name: 'MultigameContestList', component: MultigameContestList, exact: true},
   { path: '/logout', name: 'logout', component: Logout},
   {
    path: '/game_center/Teams',
    name: 'Teams',
    component: Teams
    },
    {
      path: '/contest/fixturecontest/:league_id/:season_game_uid/:tab',
      name: 'FixtureContest',
      component: FixtureContest,
      exact: true
    },
   {
     path: '/manage_user',
     name: 'Manageuser',
     component: Manageuser,
     exact: true
  },
  {
    path: '/add_user',
    name: 'adduser',
    component: AddUser,
    exact: true
  },
  {
    path: '/profile/:user_unique_id',
    name: 'Profile',
    component: Profile,
    exact: true
  },
  //Scoring section
  {
    path: '/manage_scoring/',
    name: 'ManageScoring',
    component: ManageScoring,
    exact: true
  },
  //CMS
  {
    path: '/cms/lobby_banner/',
    name: 'LobbyBanner',
    component: LobbyBanner,
    exact: true
  },
  {
    path: '/cms/app_banner/',
    name: 'AppBanner',
    component: AppBanner,
    exact: true
  },
  {
    path: '/deals/deal_list/',
    name: 'Deals',
    component: deals,
    exact: true
  },
  {
    path: '/cms/background_image/',
    name: 'BackgroundImage',
    component: BackgroundImage,
    exact: true
  },
  {
    path: '/cms/cms/',
    name: 'CMS',
    component: CMS,
    exact: true
  },
  {
    path: '/marketing/referral_amount/',
    name: 'REFERRALAMOUNT',
    component: REFERRALAMOUNT,
    exact: true
  },
  {
    path: '/marketing/promo_code/',
    name: "PROMOCODE",
    component: PROMOCODE,
    exact: true
  },
  {
    path: '/marketing/promo_code/details/:promo_code/:promo_type',
    name: "PROMOCODEDETAILS",
    component: PROMOCODEDETAILS,
    exact: true
  },
  {
    path: '/finance/withdrawal_list',
    name: "WITHDRAWAL_LIST",
    component: WITHDRAWAL_LIST,
    exact: true
  },
  {
    path: '/report/user_report',
    name: "USER_REPORTS",
    component: USER_REPORTS,
    exact: true
  },
  {
    path: '/report/user_money_paid',
    name: "USER_MONEY_PAID",
    component: USER_MONEY_PAID,
    exact: true
  },
  {
    path: '/report/user_deposit_amount',
    name: "USER_DEPOSIT_MONEY",
    component: USER_DEPOSIT_MONEY,
    exact: true
  },
  {
    path: '/report/referral_report',
    name: "REFERRAL_REPORT",
    component: REFERRAL_REPORT,
    exact: true
  },
  {
    path: '/report/contest_report',
    name: "CONTEST_REPORT",
    component: CONTEST_REPORT,
    exact: true
  },
  {
    path: '/finance/transaction_list',
    name: "TRANSACTION_LIST",
    component: TRANSACTION_LIST,
    exact: true
  },
  {
    path: '/language-upload',
    name: "LANGUAGE_UPLOAD",
    component: LANGUAGE_UPLOAD,
    exact: true
  },
  {
    
    path: '/finance/contest_detail/:id',
    name: "CONTEST_DETAILS",
    component: CONTEST_DETAILS,
    exact: true
  },
  {
    path: '/coins/dashboard',
    name: "CoinsDashboard",
    component: CoinsDashboard,
    exact: true
  },
  {
    path: '/coins/setting',
    name: "CoinsSetting",
    component: CoinsSetting,
    exact: true
  },
  {
    path: '/coins/redeem',
    name: "Redeem",
    component: Redeem,
    exact: true
  },
  {
    path: '/coins/promotions',
    name: "promotions",
    component: Promotions,
    exact: true
  },
  {
    path: '/coins/promotions/:pending',
    name: "promotions",
    component: Promotions,
    exact: true
  },
  {
    path: '/coins/question-details/:qid',
    name: "QuestionDetails",
    component: QuestionDetails,
    exact: true
  },
  {
    path: '/coins/top-earner',
    name: "TopEarner",
    component: TopEarner,
    exact: true
  },
  {
    path: '/coins/top-redeemer',
    name: "TopEarner",
    component: TopEarner,
    exact: true
  },
  {
    path: '/coins/coins-distributed',
    name: "TopEarner",
    component: TopEarner,
    exact: true
  },
  {
    path: '/coins/coin-redeem',
    name: "RedeemCoin",
    component: RedeemCoin,
    exact: true
  },
  {
    path: '/coins/spinthewheel',
    name: "SpinTheWheel",
    component: SpinTheWheel,
    exact: true
  },
  {
    path: '/prediction/module',
    name: "PredictionType",
    component: PredictionType,
    exact : true
  },
  {
    path: '/open-predictor/module',
    name: "OpenPredictionType",
    component: OpenPredictionType,
    exact : true
  },
  {
    path: '/prize-open-predictor/module',
    name: "PrizeOpenPredictionType",
    component: PrizeOpenPredictionType,
    exact: true
  },
  {
    path: '/prediction',
    name: "PredictionType",
    component: PredictionType,
    exact: true
  },
  {
    path: '/open-predictor',
    name: "OpenPredictionType",
    component: OpenPredictionType,
    exact: true
  },
  {
    path: '/prize-open-predictor',
    name: "PrizeOpenPredictionType",
    component: PrizeOpenPredictionType,
    exact: true
  },
  {
    path: '/prediction/dashboard',
    name: "PredictionDashboard",
    component: PredictionDashboard,
    exact: true
  },
  {
    path: '/open-predictor/dashboard',
    name: "OpenPredictionDashboard",
    component: OpenPredictionDashboard,
    exact: true
  },
  {
    path: '/prize-open-predictor/dashboard',
    name: "PrizeOpenPredictionDashboard",
    component: PrizeOpenPredictionDashboard,
    exact: true
  },
  {
    path: '/prediction/most-win',
    name: "MostWinBid",
    component: MostWinBid,
    exact: true
  },
  {
    path: '/open-predictor/most-win',
    name: "OpenMostWinBid",
    component: OpenMostWinBid,
    exact: true
  },
  {
    path: '/prize-open-predictor/most-answer',
    name: "PrizeOpenMostWinBid",
    component: PrizeOpenMostWinBid,
    exact: true
  },
  {
    path: '/prediction/most-bid',
    name: "MostWinBid",
    component: MostWinBid,
    exact: true
  },
  {
    path: '/open-predictor/most-bid',
    name: "OpenMostWinBid",
    component: OpenMostWinBid,
    exact: true
  },
  {
    path: '/prize-open-predictor/most-attempt',
    name: "PrizeOpenMostWinBid",
    component: PrizeOpenMostWinBid,
    exact: true
  },
  {
    path: '/prediction/fixture',
    name: "PredictionFixture",
    component: PredictionFixture,
    exact: true
  },
  
  {
    path: '/prediction/set-prediction/:fixturetype/:seasongameid/:sportsid',
    name: "SetPrediction",
    component: SetPrediction,
    exact : true
  },
  {
    path: '/merchandise',
    name: "Merchandise",
    component: Merchandise,
    exact : true
  },  
  {
    path: '/pickem/picks',
    name: "ViewPicks",
    component: ViewPicks,
    exact : true
  },
  {
    path: '/pickem/leagues',
    name: "LeaguesPlayers",
    component: LeaguesPlayers,
    exact : true
  },
  {
    path: '/pickem/dashboard',
    name: "PickemDasboard",
    component: PickemDasboard,
    exact : true
  },
  {
    path: '/pickem/most-win',
    name: "PickemMostWinBid",
    component: PickemMostWinBid,
    exact: true
  },
  {
    path: '/pickem/most-bid',
    name: "PickemMostWinBid",
    component: PickemMostWinBid,
    exact: true
  },
  {
    path: '/open-predictor/set-prediction/:category_id/:type',
    name: "OpenSetPrediction",
    component: OpenSetPrediction,
    exact: true
  },
  {
    path: '/prize-open-predictor/set-prediction/:category_id/:type',
    name: "PrizeOpenSetPrediction",
    component: PrizeOpenSetPrediction,
    exact: true
  },
  {
    path: '/prediction/completed-questions/:season_game_uid',
    name: "PredictionCompletedQues",
    component: PredictionCompletedQues,
    exact: true
  },
  {
    path: '/open-predictor/completed-questions/:season_game_uid',
    name: "OpenPredictionCompletedQues",
    component: OpenPredictionCompletedQues,
    exact: true
  },
  {
    path: '/prize-open-predictor/completed-questions/:season_game_uid',
    name: "PrizeOpenPredictionCompletedQues",
    component: PrizeOpenPredictionCompletedQues,
    exact: true
  },
  {
    path: '/open-predictor/category/',
    name: "PredictionCategory",
    component: PredictionCategory,
    exact: true
  },
  {
    path: '/prize-open-predictor/category/',
    name: "PrizePredictionCategory",
    component: PrizePredictionCategory,
    exact: true
  },
  {
    path: '/open-predictor/create-category/',
    name: "PredictionCreateCategory",
    component: PredictionCreateCategory,
    exact: true
  },
  {
    path: '/prize-open-predictor/create-category/',
    name: "PrizePredictionCreateCategory",
    component: PrizePredictionCreateCategory,
    exact: true
  },
  {
    path: '/prize-open-predictor/set-prize/',
    name: "SetPrize",
    component: SetPrize,
    exact: true
  },
  {
    path: '/DFS/season_schedule/:leagueid/:gameid/:sportsid/:tab',
    name: "SEASON_SCHEDULE",
    component: SEASON_SCHEDULE,
    exact: true
  },
  {
    path: '/pickem/create-pick',
    name: "CreateNewPick",
    component: CreateNewPick,
    exact: true
  },
  {
    path: '/pickem/userlist',
    name: "JoinedUserList",
    component: JoinedUserList,
    exact: true
  },
  {
    path: '/game_center/update-salary/:league_id/:season_game_uid',
    name: "FixtureUpdateSalary",
    component: FixtureUpdateSalary,
    exact: true
  },
  {
    path: '/change-password',
    name: "ChangePassword",
    component: ChangePassword,
    exact: true
  },
  {
    path: '/freetwoplay/create_mini_league',
    name: "CreateMiniLeague",
    component: CreateMiniLeague,
    exact : true
  },
  {
    path: '/freetwoplay/edit_mini_league/:mini_league_uid',
    name: "EditMiniLeague",
    component: EditMiniLeague,
    exact : true
  },
  {
    path: '/freetwoplay/edit_mini_league_fixture/:mini_league_uid',
    name: "EditMiniLeagueFixture",
    component: EditMiniLeagueFixture,
    exact : true
  },
  {
    path: '/freetwoplay/mini_league_detail/:mini_league_uid',
    name: "MiniLeagueDetail",
    component: MiniLeagueDetail,
    exact : true
  },
  {
    path: '/system-users/userslist',
    name: "SystemUsersList",
    component: SystemUsersList,
    exact: true
  },
  {
    path: '/system-users/add-system-users/:league_id/:season_game_uid/:contest_unique_id',
    name: "AddSystemUser",
    component: AddSystemUser,
    exact: true
  },
  {
    path: '/marketing/new_campaign',
    name: "CreateNewCampaign",
    component: CreateNewCampaign,
    exact: true
  },
  {
    path: '/marketing/custome-template',
    name: "CustomTemplate",
    component: CustomTemplate,
    exact: true
  },
  {
    path: '/marketing/userbase-list/:ub_list_id',
    name: "CreateUserbaseList",
    component: CreateUserbaseList,
    exact: true
  },
   {
     path: '/admin-role/add-role',
     name: 'AddRole',
     component: AddRole,
     exact: true
   }, 
   {
     path: '/manage-role',
     name: 'AdminRoleList',
     component: AdminRoleList,
     exact: true
   }, 
   {
     path: '/welcome-admin',
     name: 'WelcomeAdmin',
     component: WelcomeAdmin,
     exact: true
   },
  {
    path: '/distributors/',
    name: "DISTRIBUTOR",
    component: DISTRIBUTOR,
    exact: true
  }, 
  {
    path: '/distributors/add',
    name: "ADD_DISTRIBUTOR",
    component: ADD_DISTRIBUTOR,
    exact: true
  }, 
  {
    path: '/distributors/detail/:unique_id',
    name: "DISTRIBUTOR",
    component: DISTRIBUTOR,
    exact: true
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    component: Affiliate,
    exact: true
  }, 
  {
    path: '/add-affiliate/:afphone',
    name: 'AddAffiliate',
    component: AddAffiliate,
    exact: true
  }, 
  {
    path: '/affiliates',
    name: 'AffiliateDashboard',
    component: AffiliateDashboard,
    exact: true
  }, 
  {
    path: '/affiliates-users/:uid',
    name: 'AffiliateUsers',
    component: AffiliateUsers,
    exact: true
  },
  {
    path: '/cms/hub-page',
    name: 'HubPage',
    component: HubPage,
    exact: true
  },
  {
    path: '/cms/lobby',
    name: 'Lobby',
    component: Lobby,
    exact: true
  },
  {
    path: '/settings/minimum-withdrawal',
    name: 'MinimumWithdrawl',
    component: MinimumWithdrawl,
    exact: true
  },
  {
    path: '/settings/wallet',
    name: 'WalletSetting',
    component: WalletSetting,
    exact: true
  },
  {
    path: '/settings/email',
    name: 'EmailSetting',
    component: EmailSetting,
    exact: true
  },
  {
    path: '/coins/buy-coins',
    name: "BuyCoin",
    component: BuyCoin,
    exact: true
  }, 
  {
    path: '/coins/buy-coins-report/:coin_package_id',
    name: "BuyCoinUserReport",
    component: BuyCoinUserReport,
    exact: true
  },
  {
    path: '/app_config',
    name: 'AppSettingForm',
    component: AppSettingForm,
    exact: true
  },
  {
    path: '/game_center/category',
    name: 'ContestCategory',
    component: ContestCategory,
    exact: true
  },
  {
    path: '/marketing/referral_setprize',
    name: "ReferralSetprize",
    component: ReferralSetprize,
    exact: true
  }, {
    path: '/marketing/referral_leaderboard',
    name: "ReferralLeaderboard",
    component: ReferralLeaderboard,
    exact: true
  },
  {

    path: '/network-game',
    name: "NetworkGame",
    component: NetworkGame,
    exact: true
  },
  {
    path: '/seasonlong-contests/details/:league_id/:contest_unique_id',
    //system-users/add-ntwk-system-users/:league_id/:season_game_uid/:contest_unique_id
    name: "NetworkGameDetails",
    component: NetworkGameDetails,
    exact: true
  }, 
  {
    path: '/network-game/contest-report',
    name: "NetworkContestReport",
    component: NetworkContestReport,
    exact: true
  }, 
  {
    path: '/network-game/commission-history',
    name: "NetworkCommission",
    component: NetworkCommission,
    exact: true
  },
   {
     path: '/system-users/add-ntwk-system-users/:league_id/:season_game_uid/:contest_unique_id',
     name: "AddNetwGameSysUser",
     component: AddNetwGameSysUser,
     exact: true
   },
  {
    path: '/leaderboard/referral',
    name: "Referral_Leaderboard",
    component: Referral_Leaderboard,
    exact: true
  },
  {
    path: '/leaderboard/depositors',
    name: "Depositor_Leaderboard",
    component: Depositor_Leaderboard,
    exact: true
  },
  {
    path: '/leaderboard/winnings',
    name: "Winning_Leaderboard",
    component: Winning_Leaderboard,
    exact: true
  },
  {
    path: '/leaderboard/feedback',
    name: "Feedback_Leaderboard",
    component: Feedback_Leaderboard,
    exact: true
  },
  {
    path: '/leaderboard/timespent',
    name: "TimeSpent_Leaderboard",
    component: TimeSpent_Leaderboard,
    exact: true
  },
  {
    path: '/leaderboard/topteams',
    name: "TopTeam_Leaderboard",
    component: TopTeam_Leaderboard,
    exact: true
  },
  {
    path: '/leaderboard/withdrawal',
    name: "Withdrawal_Leaderboard",
    component: Withdrawal_Leaderboard,
    exact: true
  },
  {
    path: '/report/match_report',
    name: "UserMatchReport",
    component: UserMatchReport,
    exact: true
  },
   {
     path: '/accounting/dashboard',
     name: "GSTDashboard",
     component: GSTDashboard,
     exact: true
   }, {
     path: '/accounting/reports',
     name: "GSTReports",
     component: GSTReports,
     exact: true
   },
   {
     path: '/settings/reward',
     name: "Reward",
     component: Reward,
     exact: true
   },
   {
    path: '/league-management',
    name: "LeagueManagement",
    component: LeagueManagement,
    exact: true
  },
  {
    path: '/league-settings',
    name: "LeagueSettings",
    component: LeagueSettings,
    exact: true
  },
  {
    path: '/remaining-fixture',
    name: "RemainingFixture",
    component: RemainingFixture,
    exact: true
  },
  {
    path: '/select-fixtures',
    name: "SelectFixtures",
    component: SelectFixtures,
    exact: true
  },
  {
    path: '/week-management',
    name: "WeekManagements",
    component: WeekManagements,
    exact: true
  },
  {
    path: '/mini-leagues',
    name: "MiniLeague",
    component: MiniLeague,
    exact: true
  },
  {
    path: '/prizes',
    name: "Prizes",
    component: Prizes,
    exact: true
  },
  {
    path: '/draft-starter-pack',
    name: "DraftStarterPack",
    component: DraftStarterPack,
    exact: true
  },
  {
    path: '/competitions/competitions-list',
    name: "CompetitionsList",
    component: CompetitionsList,
    exact: true
  },
  {
    path: '/competitions/create-private',
    name: "CreatePrivateCompetition",
    component: CreatePrivateCompetition,
    exact: true
  },
  {
    path: '/competitions/create-public',
    name: "CreatePublicCompetition",
    component: CreatePublicCompetition,
    exact: true
  },
  {
    path: '/competitions/manage-competitions',
    name: "ManageCompetitions",
    component: ManageCompetitions,
    exact: true
  }
  ,
  {
    path: '/competitions/manage-competitions-detail',
    name: "CompetitionsDetail",
    component: CompetitionsDetail,
    exact: true
  }
  ,
  {
    path: '/shop',
    name: "Shop",
    component: Shop,
    exact: true
  },
  {
    path: '/promocode',
    name: "PromoCode",
    component: PromoCode,
    exact: true
  },
  {
    path: '/promocode/details/:promo_code/:promo_type/:tab',
    name: "PromoCodeDetail",
    component: PromoCodeDetail,
    exact: true
  },
  {
    path: '/PlayerPack',
    name: "PlayerPack",
    component: PlayerPack,
    exact: true
  },
  {
    path: '/refferralcode',
    name: "RefferralCode",
    component: RefferralCode,
    exact: true
  },
  {
    path: '/refferralcode/refferral-report',
    name: "RefferralReport",
    component: RefferralReport,
    exact: true
  },
  {
    path: '/shop/coin-bundle-list/:coin_bundle_id',
    name: "CoinBundleList",
    component: CoinBundleList,
    exact: true
    
  }

];

export default routes;
