import React, { Component, Fragment } from "react";
import { Row, Col, Table } from 'reactstrap';
import _ from 'lodash';
import * as NC from "../../helper/NetworkingConstants";
import WSManager from "../../helper/WSManager";
import { notify } from 'react-notify-toast';
import Pagination from "react-js-pagination";
import { MomentDateComponent } from "../../components/CustomComponent";

export default class PromoCodeDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TotalPromo: 0,
            PERPAGE: NC.ITEMS_PERPAGE,
            CURRENT_PAGE: 1,
            promo_type: this.props.match.params.promo_type,
        }
    }
    componentDidMount() {
        this.getPromoCodeDetail()
    }
    getPromoCodeDetail() {
        let { CURRENT_PAGE, PERPAGE } = this.state
        let params = {
            items_perpage: PERPAGE,
            total_items: 0,
            current_page: CURRENT_PAGE,
            sort_order: "DESC",
            sort_field: "PCE.added_date",
            promo_code: this.props.match.params.promo_code,
        }

        WSManager.Rest(NC.baseURL + NC.GET_PROMO_CODE_DETAIL, params).then(ResponseJson => {
            if (ResponseJson.response_code == NC.successCode) {
                this.setState({
                    PromoCodeList: ResponseJson.data.result,
                    TotalPromo: ResponseJson.data.total
                })

            } else {
                notify.show(NC.SYSTEM_ERROR, "error", 3000)
            }
        }).catch(error => {
            notify.show(NC.SYSTEM_ERROR, "error", 3000)
        })
    }
    handlePageChange(current_page) {
        if (current_page !== this.state.CURRENT_PAGE )
        {
            this.setState({
                CURRENT_PAGE: current_page
            }, () => {
                this.getPromoCodeDetail();
            });
        }
    }
    render() {
        let { promo_type, PromoCodeList, CURRENT_PAGE, PERPAGE, TotalPromo } = this.state
        return (
            <div className="animated fadeIn promocode-view mt-4">
                <Row className="mb-5">
                    <Col md={12}>
                        <h1 className="h1-cls">Promo Code Detail List</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="table-responsive common-table">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="left-th text-center">Type</th>
                                    <th>Promo code</th>
                                    <th>Name</th>
                                    <th>Amount Received</th>
                                    {(promo_type == 3) ?
                                        <Fragment>
                                            <th>Game Name</th>
                                            <th>Entry Fee</th>
                                            <th>Contest Scheduled Date</th>
                                        </Fragment>
                                        :
                                        <th>Deposit amount</th>
                                    }
                                    <th>Promocode Used Date</th>
                                    <th className="right-th">Status</th>
                                </tr>
                            </thead>
                            {
                                TotalPromo > 0 ?
                                    _.map(PromoCodeList, (item, idx) => {
                                        return (
                                            <tbody key={idx}>
                                                <tr>
                                                    <td>
                                                        {
                                                            item.type == 0 &&
                                                            <td>First Deposit</td>
                                                        }
                                                        {
                                                            item.type == 1 &&
                                                            <td>Deposit Range</td>
                                                        }
                                                        {
                                                            item.type == 2 &&
                                                            <td>Promo Code</td>
                                                        }
                                                        {
                                                            item.type == 3 &&
                                                            <td>Contest Join</td>
                                                        }
                                                    </td>
                                                    <td>{item.promo_code}</td>
                                                    <td onClick={() => this.props.history.push("/profile/" + item.user_unique_id)} className="user-name text-ellipsis">{item.user_full_name}</td>
                                                    {(promo_type == 3) ?
                                                        <Fragment>
                                                            <td>{item.amount_received}</td>
                                                            <td>{item.contest_name}</td>
                                                            <td>{item.entry_fee}</td>
                                                        </Fragment>
                                                        :
                                                        <td>{item.amount_received}</td>
                                                    }
                                                    <td>{item.deposit_amount}</td>
                                                    <td>
                                                        <MomentDateComponent data={{ date: item.added_date, format: "D-MMM-YYYY hh:mm A" }} />
                                                    </td>
                                                    <td>
                                                        {
                                                            item.status == "0" ? "Pending" : item.status == "1" ? "Success" : item.status == "2" ? "Failed" : '--'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                    :
                                    <tbody>
                                        <tr>
                                            <td colSpan="12">
                                                <div className="no-records">No Records Found.</div>
                                            </td>
                                        </tr>
                                    </tbody>
                            }
                        </Table>
                    </Col>
                </Row>
                {TotalPromo > PERPAGE &&
                    <div className="custom-pagination lobby-paging">
                        <Pagination
                            activePage={CURRENT_PAGE}
                            itemsCountPerPage={PERPAGE}
                            totalItemsCount={TotalPromo}
                            pageRangeDisplayed={5}
                            onChange={e => this.handlePageChange(e)}
                        />
                    </div>
                }
            </div>
        )
    }
} 